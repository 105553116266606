import { Breakpoints } from '@angular/cdk/layout';
import type { HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CasesBackendService, InventoryApiService, P2pApiNewService, ReferralsApiService } from '@dev-fast/backend-services';
import type { ICaseItemDtoV2, IInventoryItemDetailed, IKitResponseDto, IMarketplaceKitData } from '@dev-fast/types';
import { MAIN_LANG, MarketSortingTypes, ModalNames, NotificationStatus, NotificationType } from '@dev-fast/types';
import { RouterNavigation } from '@ngxs/router-plugin';
import type { StateContext } from '@ngxs/store';
import { Action, Selector, State, Store } from '@ngxs/store';
import type { Observable } from 'rxjs';
import { catchError, EMPTY, tap } from 'rxjs';

import { LanguageService, LanguageState, SetLanguage } from '@app/core/language-service';
import { NotificationsService } from '@app/core/notification-service';
import { SetLayoutType } from '@app/core/state/layout';
import { ModalsState, OpenModal } from '@app/core/state/modals';
import type { RouterStateParams } from '@app/core/state/utils';

import { GetAvailabilityItemInCases, GetCaseItemDetails, GetFullSkinData, GetItemAvailabilityOnThenMarket } from './skins.actions';
import type { SkinsStateModel } from './skins-state.model';
import { SKINS_INITIAL_STATE } from './skins-state.model';

@State<SkinsStateModel>({
  name: 'skins',
  defaults: SKINS_INITIAL_STATE,
})
@Injectable()
export class SkinsState {
  readonly #apiService = inject(ReferralsApiService);
  readonly #casesBackendService = inject(CasesBackendService);
  readonly #inventoryBackendService = inject(InventoryApiService);
  readonly #notificationsService = inject(NotificationsService);
  readonly #p2pService = inject(P2pApiNewService);
  readonly #router = inject(Router);
  readonly #store = inject(Store);

  constructor() {
    this.#apiService
      .bonusCaseReceived()
      .pipe(
        tap(
          (value) =>
            !this.#store.selectSnapshot(ModalsState.activeModals).includes(ModalNames.CASES_BONUS) &&
            this.#store.dispatch(new OpenModal(ModalNames.CASES_BONUS, value.payload)),
        ),
      )
      .subscribe();
  }

  @Selector()
  static itemAvailabilityInCases({ itemAvailabilityInCases }: SkinsStateModel): ICaseItemDtoV2[] | null {
    return itemAvailabilityInCases;
  }

  @Selector()
  static itemDetailsRequestState({ itemDetailsRequestState }: SkinsStateModel): boolean | null {
    return itemDetailsRequestState;
  }

  @Selector()
  static itemDetails({ itemDetails }: SkinsStateModel): IInventoryItemDetailed | null {
    return itemDetails;
  }

  @Selector()
  static direction({ direction }: SkinsStateModel): string {
    return direction;
  }

  @Selector()
  static itemAvailabilityOnThenMarket({ itemAvailabilityOnThenMarket }: SkinsStateModel): IMarketplaceKitData[] | null {
    return itemAvailabilityOnThenMarket;
  }

  @Action(SetLayoutType)
  setLayoutType({ patchState }: StateContext<SkinsStateModel>, { type }: SetLayoutType): void {
    const direction = [Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.XSmall].indexOf(type) != -1 ? 'mobile' : 'desktop';
    patchState({
      direction,
    });
  }

  @Action(GetFullSkinData)
  getFullData({ dispatch, patchState }: StateContext<SkinsStateModel>, { params }: GetFullSkinData): void {
    const { shortName, skin, name } = params;
    const snapshotLang = this.#store.selectSnapshot(LanguageState.lang).path;
    const isAcceptedLanguageCode = LanguageService.isAcceptedLanguageCode(snapshotLang);
    const lang = isAcceptedLanguageCode ? snapshotLang.split('_')[0].toUpperCase() : MAIN_LANG.toUpperCase();
    const itemCredentials = {
      shortName: shortName.replace(/_/g, ' ').replace('%E2%98%85', '★'),
      skin: skin.replace(/_/g, ' '),
      lang,
    };
    const decodedName = name.replace(/_/g, ' ').replace(/%7C/g, '|').replace(/%7B/g, '(').replace(/%7D/g, ')').replace('%E2%98%85', '★');
    patchState({ itemDetailedMeta: itemCredentials });
    dispatch([new GetCaseItemDetails(itemCredentials), new GetAvailabilityItemInCases(decodedName)]);
  }

  @Action(RouterNavigation<RouterStateParams>)
  navigate({ dispatch, patchState }: StateContext<SkinsStateModel>, { routerState }: RouterNavigation<RouterStateParams>): void {
    const { queryParams } = routerState;
    const isSkinItemPage = routerState.url.includes('/skins/item/');
    const isModalOpened = routerState.url.includes('modal:transaction');

    if (isSkinItemPage && !isModalOpened && queryParams) {
      const route = routerState.url.split('/');
      const [shortName, skin, name] = route[route.length - 1].split('__');

      if (!!shortName && !!skin && !!name) {
        dispatch(new GetFullSkinData({ shortName, skin, name }));
      }
    }
  }

  @Action(GetAvailabilityItemInCases)
  getAvailabilityItemInCases(
    { patchState }: StateContext<SkinsStateModel>,
    { name }: GetAvailabilityItemInCases,
  ): Observable<ICaseItemDtoV2[]> {
    return this.#casesBackendService.getAvailabilityItemInCases(name).pipe(
      tap((response) => {
        patchState({
          itemAvailabilityInCases: response,
        });
      }),
    );
  }

  @Action(GetCaseItemDetails)
  getCaseItemDetails(
    { patchState, dispatch }: StateContext<SkinsStateModel>,
    { params }: GetCaseItemDetails,
  ): Observable<IInventoryItemDetailed | []> {
    return this.#inventoryBackendService.getInventoryItemDetailed(params).pipe(
      tap((response) => {
        patchState({
          itemDetails: response,
          itemDetailsRequestState: true,
        });
        const req = {
          minPrice: null,
          maxPrice: null,
          minOverprice: null,
          maxOverprice: null,
          sortBy: MarketSortingTypes.MIN_PRICE,
          query: response.name.replace(/ *\([^)]*\) */g, '').replace('StatTrak™ ', ''),
        };
        dispatch(new GetItemAvailabilityOnThenMarket(req));
      }),
      catchError((error) => {
        if (error.status === 404) {
          this.#router.navigate(['not-found'], { replaceUrl: true });
        }
        patchState({
          itemDetails: null,
          itemDetailsRequestState: false,
        });
        return this.#onError(error);
      }),
    );
  }

  @Action(GetItemAvailabilityOnThenMarket, { cancelUncompleted: true })
  getItems({ patchState }: StateContext<SkinsStateModel>, { params }: GetItemAvailabilityOnThenMarket): Observable<IKitResponseDto> {
    return this.#p2pService.getMarketItems(params).pipe(
      tap((response) => {
        patchState({
          itemAvailabilityOnThenMarket: response.kits,
        });
      }),
      catchError((error) => this.#onError(error)),
    );
  }

  @Action(SetLanguage, { cancelUncompleted: true })
  changeLocale({ dispatch, getState, patchState }: StateContext<SkinsStateModel>, { locale }: SetLanguage): void {
    const { itemDetailedMeta } = getState();
    if (itemDetailedMeta) {
      let lang = locale.path.split('_')[0].toUpperCase();
      if (!LanguageService.isAcceptedLanguageCode(locale.path)) {
        lang = MAIN_LANG.toUpperCase();
      }
      patchState({ itemDetailedMeta: { ...itemDetailedMeta, lang } });
      dispatch(new GetCaseItemDetails({ ...itemDetailedMeta, lang }));
    }
  }

  #onError(errorResponse: HttpErrorResponse): Observable<never> {
    this.#notificationsService.addNotification({
      id: Date.now(),
      type: errorResponse.error?.type ? errorResponse.error.type : NotificationType.Error,
      icon: 'warning',
      message: this.#errorMessageFormat(errorResponse),
      createDate: Date.now(),
      system: true,
      status: NotificationStatus.new,
    });
    return EMPTY;
  }

  #errorMessageFormat(errorResponse: any): any {
    if (errorResponse.message) {
      return errorResponse.message;
    }
    if (typeof errorResponse.error === 'string') {
      return errorResponse.error;
    }
    return 'Error';
  }
}
