import { inject, Injectable } from '@angular/core';
import type {
  ICaseItemDetailedReq,
  ICaseItemDtoV2,
  IFilterFormContent,
  IInventoryItemDetailed,
  IInventoryItemDetailedReq,
  IMarketplaceKitData,
  IP2pPurchaseItem,
  ISteamStoreInventory,
  IUserP2pPermissionInterface,
  MarketSortingTypes,
  Widgets,
} from '@dev-fast/types';
import { ModalNames } from '@dev-fast/types';
import { Actions, ofActionSuccessful, Select } from '@ngxs/store';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { StateReset } from 'ngxs-reset-plugin';
import type { Observable } from 'rxjs';

import { ChangeActiveWidgets, ToggleBackground } from '@app/core/state/layout';
import { OpenModal } from '@app/core/state/modals';
import { P2pBuyingState, P2pMarketState, ToggleSelected } from '@app/core/state/p2p';
import { GetP2pPermissions, UserState } from '@app/core/state/user-store';

import { GetAvailabilityItemInCases, GetCaseItemDetails, GetFullSkinData, GetItemAvailabilityOnThenMarket } from './skins.actions';
import { SkinsState } from './skins.state';

@Injectable({ providedIn: 'root' })
export class SkinsService {
  readonly #actions$ = inject(Actions);
  @Select(SkinsState.itemDetails)
  readonly itemDetails$!: Observable<IInventoryItemDetailed>;

  @Select(SkinsState.itemDetailsRequestState)
  readonly itemDetailsRequestState$!: Observable<boolean | null>;

  @Select(SkinsState.itemAvailabilityOnThenMarket)
  readonly itemAvailabilityOnThenMarket$!: Observable<IMarketplaceKitData[]>;

  @Select(SkinsState.itemAvailabilityInCases)
  readonly itemAvailabilityInCases$!: Observable<ICaseItemDtoV2[]>;

  @Select(SkinsState.direction)
  readonly direction$!: Observable<string>;

  @Select(P2pBuyingState.purchasing)
  readonly purchasing$!: Observable<IP2pPurchaseItem[]>;

  @Select(UserState.p2pPermissions)
  readonly p2pPermissions$!: Observable<IUserP2pPermissionInterface>;

  readonly getMarketItemsAction$ = this.#actions$.pipe(ofActionSuccessful(GetItemAvailabilityOnThenMarket));

  @Dispatch() GetItemAvailabilityOnThenMarket = (params: IFilterFormContent<MarketSortingTypes>): GetItemAvailabilityOnThenMarket =>
    new GetItemAvailabilityOnThenMarket(params);

  @Dispatch() toggleSelected = (value: IMarketplaceKitData): ToggleSelected => new ToggleSelected(value);

  @Dispatch() getCaseItemDetails = (params: IInventoryItemDetailedReq): GetCaseItemDetails => new GetCaseItemDetails(params);
  @Dispatch()
    getItemAvailabilityInCases = (name: string): GetAvailabilityItemInCases => new GetAvailabilityItemInCases(name);

  @Dispatch() changeActiveWidgets = (widgets: Widgets[]): ChangeActiveWidgets => new ChangeActiveWidgets(widgets);
  @Dispatch() resetState = (): StateReset => new StateReset(SkinsState);
  @Dispatch() resetP2pMarketState = (): StateReset => new StateReset(P2pMarketState);

  @Dispatch() toggleBackground = (color?: string): ToggleBackground => new ToggleBackground(color);
  @Dispatch() showBatchDetailsModal = (item: IMarketplaceKitData | ISteamStoreInventory): OpenModal =>
    new OpenModal(ModalNames.P2P_KIT_DETAILS, item);

  @Dispatch() getP2pPermissions = (): GetP2pPermissions => new GetP2pPermissions();
  @Dispatch() getFullSkinData = (params: ICaseItemDetailedReq): GetFullSkinData => new GetFullSkinData(params);
}
