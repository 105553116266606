import type { ICaseItemDetailedReq, IFilterFormContent, IInventoryItemDetailedReq, MarketSortingTypes } from '@dev-fast/types';

export class GetFullSkinData {
  static readonly type = '[Skins] Get full data';

  constructor(public params: ICaseItemDetailedReq) {}
}

export class GetCaseItemDetails {
  static readonly type = '[Skins] Get item details';

  constructor(public params: IInventoryItemDetailedReq) {}
}

export class GetAvailabilityItemInCases {
  static readonly type = '[Skins] Get item availability in cases';

  constructor(public name: string) {}
}

export class GetItemAvailabilityOnThenMarket {
  static readonly type = '[Skins] Get item availability in market';

  constructor(public params: IFilterFormContent<MarketSortingTypes>) {}
}
